<template>
  <div class="vh-100 w-100 flex items-center justify-center bg-washed-red">
    <h1 class="white" style="font-size: 8rem; text-align: center">Error 404</h1>
  </div>
</template>

<script>
export default {
    name: 'Error404',
}
</script>

<style scoped></style>
